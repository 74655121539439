<script setup lang="ts">
import { buttonVariant } from "@/components/ui/button";
import MapPinIcon from "@/icons/map-pin.svg?component";
import { twMerge } from "tailwind-merge";
import type { PublicConference } from "../../server/shared";
import { getCDNImageURL } from "@/utils/cdn";
import { formatDateToMonthDay } from "@/dayjs";
import { useConferencePlatform } from "../../client/platform";
import i18n from "i18next";

const props = defineProps<{
	conference: PublicConference;
}>();

const platform = useConferencePlatform(() => props.conference);
</script>

<template>
	<div
		class="relative flex h-full flex-col justify-end overflow-hidden rounded-xl bg-cover bg-center md:rounded-2xl"
	>
		<img
			:src="getCDNImageURL(props.conference.imageKey)"
			class="h-full min-h-56 select-none object-cover sm:aspect-video"
			decoding="async"
			loading="lazy"
			width="1280"
			height="720"
		/>
		<div
			class="absolute flex w-full flex-col items-start gap-4 bg-gradient-to-t from-black/90 via-black/50 via-70% p-4 md:p-6"
		>
			<div class="flex flex-col gap-2">
				<h3
					class="line-clamp-2 text-balance text-xl font-bold text-white md:text-3xl"
				>
					{{ conference.name }}
				</h3>
				<span class="text-sm font-medium text-white/80">
					<template v-if="conference.type === 'OFFLINE'">
						<MapPinIcon
							class="mr-0.5 inline-block size-4 align-sub text-white/70"
						/>
						{{ conference.country }}, {{ conference.city }}
					</template>
					<template v-else-if="platform">
						<component
							:is="platform.icon"
							class="mx-1 inline-block size-4 align-sub"
						/>
						{{ platform.name }}
					</template>

					&bull; {{ formatDateToMonthDay(conference.startsAt) }}
				</span>
			</div>
			<div class="flex gap-1.5">
				<a
					:class="buttonVariant({ size: 'sm' })"
					:href="`/conferences/${conference.id}`"
				>
					{{ i18n.t("carousel.book") }}
				</a>
				<a
					:class="
						twMerge(
							buttonVariant({ size: 'sm', variant: 'secondary' }),
							'border-white/25 text-white backdrop-blur-sm hover:bg-white/25',
						)
					"
					:href="`/conferences/${conference.id}`"
				>
					{{ i18n.t("carousel.more") }}
				</a>
			</div>
		</div>
	</div>
</template>
