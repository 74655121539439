<script setup lang="ts">
import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from "@/components/ui/carousel";
import type { PublicConference, UserConference } from "../../server/shared";
import MainConferencesCarouselItem from "./MainConferencesCarouselItem.vue";
import { getCurrentLocale, loadI18n } from "@/i18n/client";
import { setDateFormatterLocale } from "@/dayjs";

const props = defineProps<{
	listConferences: Array<PublicConference | UserConference>;
}>();

const locale = getCurrentLocale();
const [i18n] = await Promise.all([
	loadI18n(locale),
	setDateFormatterLocale(locale),
]);
</script>

<template>
	<section class="relative border-b border-zinc-200 px-6 py-12 md:px-10">
		<Carousel
			class="container relative flex w-full flex-col xl:max-w-[1440px]"
			:opts="{ align: 'start' }"
		>
			<div class="mb-6 flex justify-between">
				<h2 class="text-3xl font-bold tracking-tight md:text-4xl">
					{{ i18n.t("carousel.title") }}
				</h2>
				<div class="flex gap-2">
					<CarouselPrevious />
					<CarouselNext />
				</div>
			</div>
			<div>
				<CarouselContent>
					<CarouselItem
						v-for="conference in props.listConferences"
						:key="conference.id"
						class="basis-full pl-5 md:basis-[55%] xl:basis-[45%]"
					>
						<MainConferencesCarouselItem :conference="conference" />
					</CarouselItem>
				</CarouselContent>
			</div>
		</Carousel>
	</section>
</template>
